import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class LedgerService {    
    async getCOAQuery(){
        var query = gql`query{
            GetChartOfAccountingIds{
                id
                name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var arrayData = [];
        if(result.data.GetChartOfAccountingIds != null){
            for (let i = 0; i < result.data.GetChartOfAccountingIds.length; i++) {
                var str = { value:result.data.GetChartOfAccountingIds[i].id, 
                            label:result.data.GetChartOfAccountingIds[i].id + ' (' + result.data.GetChartOfAccountingIds[i].name + ')'}
                arrayData.push(str);
            }
        }
        return arrayData;
    }

    async getAllLedgerData(variables){
        var query = gql`
            query($startDate:DateTime!, $endDate:DateTime!){
                GetAllLedger (StartDate: $startDate, EndDate: $endDate) {
                    id
                    transaction_number
                    transaction_date
                    contact_id
                    contact_name
                    desc
                    credit
                    debit
                    balance
                    notes
                    chart_of_account_id
                    chart_of_account_name
                    division
                    budgeting
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        
        var objData = globalfunc.objectToArrayConverter(result.data.GetAllLedger, 'General-Ledger-Data');
        return objData;
    }

    itemPDF(data){
        var arrayObject = [];
        if(data.length != 0){
            for (let i = 0; i < data.length; i++){
                var row = { 
                    id: data[i].id,
                    transaction_number: data[i].transaction_number,
                    transaction_date: kendo.toString(kendo.parseDate(data[i].transaction_date, 'yyyy-MM-dd'), 'yyyy-MM-dd'),
                    contact_id: data[i].contact_id,
                    contact_name: data[i].contact_name,
                    chart_of_account_id: data[i].chart_of_account_id,
                    chart_of_account_name: data[i].chart_of_account_name,
                    desc: data[i].desc,
                    credit: kendo.toString(data[i].credit, "n2"),
                    debit: kendo.toString(data[i].debit, "n2"),
                    balance: kendo.toString(data[i].balance, "n2"),
                    notes: data[i].notes,
                    division: data[i].division,
                    budgeting: data[i].budgeting
                }
                arrayObject.push(row);
            }
        }
        return arrayObject;
    }

    ////Excel
    balanceExcelHeaderSize() {
        var size = [
            {width: 20}, //colA
            {width: 20}, //colB
            {width: 20}, //colC
            {width: 20}, //colD
            {width: 20}, //colE
            {width: 20}, //colF
            {width: 20}, //colG
            {width: 20}, //colH
            {width: 20}, //colI
            {width: 20}, //colJ
            {width: 20}, //colK
        ];

        return size;
    }

    balanceExcel(data, variables) {
        var arrayObject = [];
        var date = 'Tanggal ' + moment(variables.startDate).format('DD MMM YYYY') + ' - ' + moment(variables.endDate).format('DD MMM YYYY');
        var numberFormat = '_(* #,##0.00_);_(* (#,##0.00);_(* "-"_);_(@_)';

        var startIndex = 6;
        var lastIndex = startIndex;

        if(data != null){
            lastIndex = lastIndex + data.length - 1;
        }

        //Header
        var row1 = {colA : 'PT BOJONG WESTPLAS',};
        arrayObject.push(row1);

        var row2 = {colA : 'BUKU BESAR',};
        arrayObject.push(row2);

        var row3 = {colA : date};
        arrayObject.push(row3);
        
        var spaceRow = {colA : null};
        arrayObject.push(spaceRow);

        var row5 = {
            colA : 'Tanggal',
            colB : 'No. Bukti',
            colC : 'Kontak',
            colD : 'Kode Akun',
            colE : 'Nama Akun',
            colF : 'Deskripsi',
            colG : 'Debit',
            colH : 'Kredit',
            colI : 'Uraian',
            colJ : 'Divisi',
            colK : 'Budgeting'
        };
        arrayObject.push(row5);

        if(data != null){
            for (let i = 0; i < data.length; i++){
                var row = {
                    colA : (data[i].transaction_date == null || data[i].transaction_date == '') ? '-' : { v: data[i].transaction_date, t: 'd', z: 'DD-MMM-YYYY' },
                    colB : data[i].transaction_number,
                    colC : data[i].contact_name,
                    colD : data[i].chart_of_account_id,
                    colE : data[i].chart_of_account_name,
                    colF : data[i].desc,
                    colG : { v: data[i].debit, t: 'n', z: numberFormat },
                    colH : { v: data[i].credit, t: 'n', z: numberFormat },
                    colI : data[i].notes,
                    colJ : data[i].division,
                    colK : data[i].budgeting,
                };
                arrayObject.push(row);
            }
        }

        return arrayObject;
    }
}

export default new LedgerService();